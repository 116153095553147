<template>
  <div class="d-flex py-4 align-center" :class="{ 'justify-center': centre }">
    <img
      src="@/v2/assets/images/WilledLogo.svg"
      position="center left"
      contain
      id="app-logo"
      :style="{ height: centre ? '60px' : 'auto', width: centre ? 'auto' : '' }"
    />
    <v-spacer v-if="!centre" />
    <div class="mr-6 align-center" id="app-phone" v-if="!centre">
      <v-icon color="black" class="mr-2">mdi-phone</v-icon>
      <div>
        <div class="font-weight-bold text-lg">1300 945 533</div>
      </div>
    </div>
    <btn-outline
      icon="mdi-account"
      @click="$store.commit('app/SET_USER_MENU', true)"
      v-if="!centre"
    />
  </div>
</template>

<script>
import BtnOutline from '../ui/BtnOutline'
export default {
  name: 'AppHeader',
  components: { BtnOutline },
  props: {
    centre: {
      default: false
    }
  },
  data: () => ({})
}
</script>

<style lang="scss">
#app-logo {
  display: block;
  width: 140px;
  height: auto;
  @media only screen and (min-width: 600px) {
    width: 180px;
  }
}
#app-phone {
  display: none;
  @media only screen and (min-width: 600px) {
    display: flex;
  }
}
</style>
